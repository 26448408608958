// CartContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import axios from 'axios';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CART':
      return action.cart;
    case 'ADD_ITEM':
      const existingItemIndex = state.findIndex(item => item.id === action.item.id);
      if (existingItemIndex !== -1) {
        const updatedState = [...state];
        updatedState[existingItemIndex].quantity += 1;
        return updatedState;
      }
      return [...state, { ...action.item, quantity: 1 }];
    case 'REMOVE_ITEM':
      return state.filter(item => item.id !== action.id);
    case 'CLEAR_CART':
      return [];
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);

  useEffect(() => {
    axios.get('/get-cart-items/').then(response => {
      dispatch({ type: 'SET_CART', cart: response.data.items });
    });
  }, []);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);