// withProviders.js
import React from 'react';
import AppWithProviders from './components/AppWithProviders/AppWithProviders';

const withProviders = (Component) => {
  return (props) => (
    <AppWithProviders>
      <Component {...props} />
    </AppWithProviders>
  );
};

export default withProviders;