import React from 'react';
import { useCart } from '../CartContext/CartContext';

const Checkout = () => {
  const { cart, dispatch } = useCart();

  const handleCheckout = () => {
    // Implement checkout logic here
    console.log('Checkout:', cart);
    dispatch({ type: 'CLEAR_CART' });
  };

  return (
    <div className="checkout">
      <h1>Checkout</h1>
      <ul>
        {cart.map(item => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
      <button onClick={handleCheckout}>Checkout</button>
    </div>
  );
};

export default Checkout;