// AppWithProviders.js
import React from 'react';
import { CartProvider } from '../CartContext/CartContext';

const AppWithProviders = ({ children }) => {
  return (
    <CartProvider>
      {children}
    </CartProvider>
  );
};

export default AppWithProviders;