import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import './Navbar.css'; // Ensure this CSS file is created
import logoImage from '../App/head_logo.svg';
import { useCart } from '../CartContext/CartContext';
import axios from 'axios';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [cartLength, setCartLength] = useState(0);
  const animation = useSpring({
    to: { opacity: isOpen ? 1 : 0, height: isOpen ? 120 : 0 },
    from: { opacity: 0, height: 0 },
  });

  const { cart } = useCart();

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const response = await axios.get('/get-cart-items/');
        const cartItems = response.data.items;
        const totalQuantity = cartItems.reduce((acc, item) => acc + item.quantity, 0);
        setCartLength(totalQuantity);
      } catch (error) {
        console.error('Error fetching cart items:', error);
      }
    };

    fetchCart();
  }, []);

  useEffect(() => {
    const totalQuantity = cart.reduce((acc, item) => acc + item.quantity, 0);
    setCartLength(totalQuantity);
  }, [cart]);

  return (
    <div className="navbar-container">
      <div className="logo-and-menu">
        <div className="logo">
          <img src={logoImage} alt="Logo" />
        </div>
        <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
          <div className={isOpen ? 'bar top cross' : 'bar top'}></div>
          <div className={isOpen ? 'bar middle' : 'bar middle show'}></div>
          <div className={isOpen ? 'bar bottom cross' : 'bar bottom'}></div>
        </div>
      </div>
      <animated.div className="navbar-links" style={animation}>
        <a href="/">Home</a>
        <a href="/whotemplates">Products</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
        <a href="#cart">Cart ({cartLength})</a>
      </animated.div>
      <div className="desktop-links">
        <a href="/">Home</a>
        <a href="/whotemplates">Products</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
        <a href="#cart">Cart ({cartLength})</a>
      </div>
    </div>
  );
};

export default Navbar;