// TemplatePricingSection.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ThumbnailCarousel from '../ThumbnailCarousel/ThumbnailCarousel';
import '../PricingSection/PricingSection.css';
import './TemplatePricingSection.css';
import { useCart } from '../CartContext/CartContext';
import Cart from '../Cart/Cart'; // Import the Cart component

const TemplatePricingSection = () => {
  const [templates, setTemplates] = useState([]);
  const { dispatch } = useCart();

  useEffect(() => {
    axios.get('/templates')
      .then(response => {
        console.log('Fetched templates:', response.data);
        setTemplates(response.data);
      })
      .catch(error => console.error('Error fetching templates:', error));

    axios.get('/get-cart-items/')
      .then(response => {
        console.log('Fetched cart items:', response.data.items);
        dispatch({ type: 'SET_CART', cart: response.data.items });
      })
      .catch(error => console.error('Error fetching cart items:', error));
  }, [dispatch]);

  const addToCart = (template) => {
    axios.post(`/add-to-cart/${template.id}/`)
      .then(response => {
        console.log('Adding to cart:', response.data.item);
        dispatch({ type: 'ADD_ITEM', item: response.data.item });
      })
      .catch(error => console.error('Error adding to cart:', error));
  };

  return (
    <div className="template-pricing-section" id="pricing">
      <Cart navigateToCheckout={() => console.log('Navigate to Checkout')} /> 
      {templates.map(template => (
        <div key={template.id} className="template-item">
          <h2>{template.name}</h2>
          <p>{template.description}</p>
          <ThumbnailCarousel template={template} />
          <button className="add-to-cart-button" onClick={() => addToCart(template)}>Add to Cart</button>
        </div>
      ))}
    </div>
  );
};

export default TemplatePricingSection;