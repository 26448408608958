// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import Navbar from './components/Navbar/Navbar';
import FullScreenVideoBackground from './components/FullScreenVideoBackground/FullScreenVideoBackground';
import PricingSection from './components/PricingSection/PricingSection';
import ContactForm from './components/ContactForm/ContactForm';
import About from './components/About/About';
import Footer from './components/Footer/Footer';
import Checkout from './components/Checkout/Checkout';
import TemplatePricingSection from './components/TemplatePricingSection/TemplatePricingSection';
import Cart from './components/Cart/Cart';
import withProviders from './withProviders';

const mountComponent = (Component, elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    const WrappedComponent = withProviders(Component);
    const root = ReactDOM.createRoot(element);
    root.render(
      <React.StrictMode>
        <WrappedComponent />
      </React.StrictMode>
    );
  }
};

mountComponent(Navbar, 'navbar-root');
mountComponent(App, 'background-root');
mountComponent(FullScreenVideoBackground, 'vidbackground-root');
mountComponent(PricingSection, 'gallery-root');
mountComponent(ContactForm, 'contact-root');
mountComponent(About, 'about-root');
mountComponent(Footer, 'footer-root');
mountComponent(Checkout, 'checkout-root');
mountComponent(TemplatePricingSection, 'template-pricing-root');
mountComponent(Cart, 'cart-root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();