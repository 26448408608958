// Cart.js
import React from 'react';
import { useCart } from '../CartContext/CartContext';
import './Cart.css'; // Optional: for custom styling

const Cart = ({ navigateToCheckout }) => {
  const { cart, dispatch } = useCart();

  const increaseQuantity = (item) => {
    dispatch({ type: 'ADD_ITEM', item });
  };

  const decreaseQuantity = (item) => {
    if (item.quantity > 1) {
      dispatch({
        type: 'ADD_ITEM',
        item: { ...item, quantity: item.quantity - 1 },
      });
    } else {
      dispatch({ type: 'REMOVE_ITEM', id: item.id });
    }
  };

  const removeItem = (id) => {
    dispatch({ type: 'REMOVE_ITEM', id });
  };

  return (
    <div className="cart">
      <h2>Cart</h2>
      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        cart.map((item) => (
          <div key={item.id} className="cart-item">
            <div>
              <h3>{item.name}</h3>
              <p>{item.description}</p>
              <p>${(item.price * item.quantity).toFixed(2)}</p> {/* Display total price for the item */}
              <div className="cart-item-controls">
                <button onClick={() => decreaseQuantity(item)}>-</button>
                <span>{item.quantity}</span>
                <button onClick={() => increaseQuantity(item)}>+</button>
                <button onClick={() => removeItem(item.id)}>Remove</button>
              </div>
            </div>
          </div>
        ))
      )}
      <button onClick={navigateToCheckout} className="checkout-button">Go to Checkout</button>
    </div>
  );
};

export default Cart;