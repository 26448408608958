import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ThumbnailCarousel.css';

const ThumbnailCarousel = ({ template }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="thumbnail-carousel">
      <Slider {...settings}>
        <div className= "template-thumbnail">
          <iframe
            src={template.url}
            width="350"
            height="400"
            title={`${template.name} Preview`}
            className="template-iframe"
            frameBorder="0"
          ></iframe>
        </div>
        <div className="template-thumbnail">
          <iframe
            src={template.url}
            width="350"
            height="400"
            title="Template Preview"
            className="template-iframe"
            frameBorder="0"
          ></iframe>
          <div className="template-thumbnail-overlay">
            <a href={template.url} target="_blank" rel="noopener noreferrer">
              <button className="view-fullsite-button">View Full Site</button>
            </a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default ThumbnailCarousel;